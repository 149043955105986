import React from 'react';

import PaymentSuccessPopUp from './acceptance/PaymentSuccessPopUp';

const ConfirmPassword = React.lazy(() => import('../views/confirm-password'));
const ForgotPassword = React.lazy(() => import('../views/forgot-password'));
const VerifyEmail = React.lazy(() => import('../views/verify-email'));
const SignInNewStudent = React.lazy(() => import('./auth/sign-in-new'));
const SignInReturningStudent = React.lazy(() => import('./auth/sign-in-returning'));

const Acceptance = React.lazy(() => import('./acceptance'));
const Assignments = React.lazy(() => import('./assignments'));
const AssignmentOverview = React.lazy(() => import('./assignments/AssignmentOverview'));
const AssignmentUpload = React.lazy(() => import('./assignments/Upload'));
const AssignmentSubmit = React.lazy(() => import('./assignments/AssignmentSubmit'));
const Onboarding = React.lazy(() => import('./auth/onboarding'));
const NoAdmission = React.lazy(() => import('./auth/onboarding/no-admission'));
const BioDataDetails = React.lazy(() => import('./biodata-details'));
const Communications = React.lazy(() => import('./communications'));
const Message = React.lazy(() => import('./communications/Message'));
const Dashboard = React.lazy(() => import('./dashboard'));
const AccommodationRegistration = React.lazy(() => import('./accommodation'));
const SportsRegistration = React.lazy(() => import('./sports'));
// const BiodataReport = React.lazy(() => import('./biodata-details/biodata-report/index'));
const LinkSentPopUp = React.lazy(() => import('./forgot-password/LinkSentPopUp'));
const Discussion = React.lazy(() => import('./forum'));
const StartNewDiscussion = React.lazy(() => import('./forum/start-new-topic'));
const DiscussionThread = React.lazy(() => import('./forum/thread'));
const Library = React.lazy(() => import('./library'));
const LibraryComplete = React.lazy(() => import('./library/LibraryComplete'));
const Medicals = React.lazy(() => import('./medicals'));
const MedicalsComplete = React.lazy(() => import('./medicals/MedicalsComplete'));
const Fees = React.lazy(() => import('./payments'));
const Invoice = React.lazy(() => import('./payments/Invoice'));
const PaymentHistory = React.lazy(() => import('./payments/PaymentHistory'));
const Receipt = React.lazy(() => import('./payments/Receipt'));
const Summary = React.lazy(() => import('./payments/Summary'));
const Results = React.lazy(() => import('./results'));
const Timetable = React.lazy(() => import('./timetable'));
const AccommodationInfo = React.lazy(() => import('./accommodation/AccommodationInfo'));
const Courses = React.lazy(() => import('./course'));
const CourseOverview = React.lazy(() => import('./course/Overview'));
const SportsInformation = React.lazy(() => import('./sports/sportsComplete'));
const Exeat = React.lazy(() => import('./exeat'));
const CreatNewExeat = React.lazy(() => import('./exeat/create-new-exeat'));
const ExeatDetails = React.lazy(() => import('./exeat/exeatDetails'));
const CourseRegistration = React.lazy(() => import('./course/course-reg'));
const ViewPdfPage = React.lazy(() => import('./forum/view-pdf'));
const LearningResources = React.lazy(() => import('./learning-resources'));
const CourseResources = React.lazy(() => import('./learning-resources/course-resources'));
const ResourcePreview = React.lazy(() => import('./learning-resources/resource-preview'));

export {
    Discussion,
    Results,
    SignInNewStudent,
    SignInReturningStudent,
    ForgotPassword,
    Onboarding,
    NoAdmission,
    Dashboard,
    BioDataDetails,
    SportsRegistration,
    AccommodationRegistration,
    Medicals,
    MedicalsComplete,
    Assignments,
    Library,
    LibraryComplete,
    VerifyEmail,
    ConfirmPassword,
    Fees,
    AssignmentOverview,
    AssignmentSubmit,
    LinkSentPopUp,
    Acceptance,
    Timetable,
    Communications,
    Message,
    StartNewDiscussion,
    PaymentHistory,
    Invoice,
    Receipt,
    Summary,
    PaymentSuccessPopUp,
    CourseRegistration,
    AccommodationInfo,
    Courses,
    CourseOverview,
    SportsInformation,
    AssignmentUpload,
    // BiodataReport,
    DiscussionThread,
    Exeat,
    CreatNewExeat,
    ExeatDetails,
    ViewPdfPage,
    LearningResources,
    CourseResources,
    ResourcePreview,
};
